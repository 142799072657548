import $ from 'jquery'

export default function initClientSlider(){
  if($('.js-client-review-slider').length){
    $('.js-client-review-slider').slick({
      autoplay: true,
      autoplaySpeed: 2500,
      infinite: true,
      speed: 400,
      adaptiveHeight: true,
      fade: true,
      cssEase: 'linear',
      initialSlide: 1,
      arrows: true,
      buttons: false,
      dots: true
    });
  }
}