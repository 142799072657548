import 'magnific-popup';

function init() {
  if ($('.js-creative-gallery').length) {
    let items = [];
    // gather all presentaions to add to gallery
    $('.js-creative-gallery').find('figure').each(function(idx, element) {
      const postid = $(element).data('postid')
      const presentation = $(`.presentation[data-postid="${postid}"]`)
      items.push({src: presentation[0], type: 'inline'})
      // add presentation index to trigger element
      $(element).attr('data-index', idx)
    })
    // setup triggers for each figure to load by item index
    $('.js-creative-gallery').find('figure').each(function(idx, element) {
      $(element).on('click', function (e) {
        e.preventDefault();
        $.magnificPopup.open({
            gallery: {
              enabled: true,
              preload: [0,2]
            },
            items,
            type: 'inline'
        }, idx);
      })
    })
  }
}

export default function gallery() {
  $(function () {
    init()
  })
}
