import $ from 'jquery'

const $doc = $(document)

function handleFeatureTabsClick(e) {
  e.preventDefault();

  const itemIdx = $(e.target).data('idx');

  $('.tab-link').removeClass('is-active');
  $('.feature-tabs__group, .feature-tabs__group--mobile').removeClass('is-active');
  $(e.target).addClass('is-active');

  if ($(window).width() > 968) {
    $('.feature-tabs__group').each(function() {
      if ( itemIdx == $(this).data('idx') ) $(this).addClass('is-active');
    })
  } else {
    $(e.target).find('+ .feature-tabs__group--mobile').addClass('is-active')
  }

}

export default function initTabs(){
  $doc.on('click mouseenter', '.js-feature-tab', handleFeatureTabsClick )
}