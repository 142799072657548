import Shuffle from 'shufflejs'
import $ from 'jquery'
import imagesLoaded from 'imagesloaded'

const $doc = $(document)
const grid = document.getElementById('shuffle-grid')
const gridOptions = { itemSelector: '.js-item' }
window._shuffle = grid ? new Shuffle(grid, gridOptions) : null

function handleFilterItems(e) {
  const { group } = e.currentTarget.dataset
    e.preventDefault()
    filterItems(this, group)
}

function handleOnReady() {
  grid &&
    imagesLoaded(grid).on('progress', function (instance, image) {
      _shuffle.layout()
    })
}

function filterItems(filter, group) {
  $('.js-filter').removeClass('selected')
  $(filter).addClass('selected')
  const groupid = $(filter).data('catid')

  //-- This is the main filtering function--//
  _shuffle.filter(group)
  //-- END main filtering function --//

  const event = new CustomEvent('beforeNewQuery', {
    detail: { groupid, group }
  })
  document.dispatchEvent(event)

}

export default function filter() {
  $doc.on('click', '.js-filter', handleFilterItems)
  $(function () {
    handleOnReady()
  })
}
