import $ from 'jquery';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import Headroom from 'headroom.js';
import 'slick-carousel';
import './lib/mega-menu';
import './lib/home-animations';
import sal from 'sal.js';
import initTabs from './lib/feature-tabs';
import initClientSlider from './lib/client-review-slider';
import initNavAccordion from './lib/nav-accordion';
import filter from './lib/filter';
import gallery from './lib/gallery';

const Mangrove = {
  init() {
    this.cache();
    this.events();
    this.checkIE11();
    this.initSal();
    this.initHeader();
    this.initFooter();
    this.initCheckboxes();
    this.initCTABg();
    // this.initCTACards();
    this.initCareerCards();
    this.initAjax();
    this.animateAnchor();
    this.animateQuotes();
    this.homepageParallax();
    this.checkAnchorsOnLoad();
    this.logosSlider();
    initTabs()
    initClientSlider()
    initNavAccordion()
    filter()
    gallery()
  },
  cache() {
    this.$doc = $(document);
    this.$win = $(window);
    this.$html = $('html');
    this.$body = $('body');
    this.$mainWrap = $('main');
    this.$header = $('.js-header');
    this.$mobileNav = $('.js-mobile-nav');
    this.$hamburger = $('.js-hamburger');
  },
  events() {
    this.$doc.on('click', '.js-hamburger', event => this.showMobileNav(event))
    this.$doc.on('click', '.js-share', event => this.openShareWindow(event))
    this.$doc.on('mouseenter', '.js-dashboard a', event => this.triggerModulePopup(event))
    this.$doc.on('click', '.js-scroll-to', event => this.scrollToSection(event))
    this.$doc.on('click', '.js-get-started a', event => this.goToGetStartedForm(event))
    this.$doc.on('click', 'body', event => this.resetDashBoardState(event))
    jQuery(document).on('gform_confirmation_loaded', (event, id) => this.onFormSubmit(event, id))

  },
  initSal() {
    sal({
      threshold: 0.3,
    });
  },
  checkAnchorsOnLoad() {
   if (window.location.hash) {
    $(window).on('load', function() {
      $('html, body').scrollTop( $( window.location.hash ).offset().top - 150 )
    })
   }
  },
  homepageParallax(){
    if($("body").hasClass("home")){
      $(".home .hero__bg--image").addClass("home-parallax__img");
      $(".home .building-block--form_text .building-block__bg--image").addClass("home-parallax__img");
      $(".home .building-block--services .building-block__bg--image").addClass("home-parallax__img");
      $(".home .building-block--single_image_text .building-block__bg--image").addClass("home-parallax__img");
      setTimeout(function(){
        $(".home .hero__content h1").fadeIn(1300);
      }, 300);
      var lastScrollTop = 0;
      $(window).scroll(function() {
         var st = $(this).scrollTop();
         var bgPosition = $(".home .hero__bg--image").css("background-position-y").replace("px", "");
         var numberValue = parseFloat(bgPosition);
         $(".home .hero__content h1").css("marginTop", st/1.6);

         if (st > lastScrollTop){
           if ($(".home .hero__bg--image").isInViewport()) {
             $(".home .hero__bg--image").css("background-position-y", numberValue+0.2 + "px");
           }
         } else {
           if ($(".home .hero__bg--image").isInViewport()) {
             $(".home .hero__bg--image").css("background-position-y", numberValue-0.2 + "px");
           }
         }
         lastScrollTop = st;
      });
      $.fn.isInViewport = function() {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();
        return elementBottom > viewportTop && elementTop < viewportBottom;
      };
    }
    else{
      setTimeout(function(){
        $(".hero__content h1").fadeIn(1300);
      }, 300);
    }
  },
  animateQuotes(){
    if($('.js-quotes').length){
      $('.js-quotes').slick({
        autoplay: true,
        autoplaySpeed: 2500,
        infinite: true,
        speed: 400,
        adaptiveHeight: true,
        fade: true,
        cssEase: 'linear',
        initialSlide: 1,
        arrows: false,
        buttons: false,
        dots: true
      });
    }
  },
  logosSlider(){
    if ($(".js-logos").length) {
      $('.js-logos').slick({
        speed: 5000,
        autoplay: true,
        autoplaySpeed: 0,
        centerMode: true,
        cssEase: 'linear',
        slidesToShow: 6,
        slidesToScroll: 1,
        // variableWidth: true,
        infinite: true,
        initialSlide: 1,
        arrows: false,
        buttons: false,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 5,
            }
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 4,
            }
          },
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 2,
            }
          },
        ]
      });
    }
  },
  animateAnchor(){
    $('a[href*="#"]').click(function(event){
      const elm = event.currentTarget;
      const skip = $(elm.hash).length && elm.hash == '#getInTouch';
      if($(this).attr('href').length > 1 && !skip){
        $('html, body').animate({
            scrollTop: $( $(this).attr('href') ).offset().top - 150
        }, 500);
        return false;
      }
    });
  },
  initCareerCards(){
    $(".open-card").on('click', function(){
      $(this).siblings(".jobs__details").find(".jobs__info").slideToggle();
      $(this).find(".open").toggleClass("visible");
      $(this).find(".hide").toggleClass("visible");
      $(this).parent().find(".jobs__cta").toggleClass("visible");
    });
  },
  // initCTACards(){
  //   $(".js-cta-card").on('mouseenter', function(){
  //     $(this).siblings(".js-cta-card").removeClass('is-active');
  //     $(this).addClass("is-active");
  //   });
  // },
  initCTABg(){
    $(".ctaBg").on('mouseenter', function(){
      var bgId = "." + $(this).attr('custom-id');
      $(bgId).css('opacity', '1');
    }).mouseleave(function(){
      var bgId = "." + $(this).attr('custom-id');
      $(bgId).css('opacity', '0');
    });

    $(".ctaBg").on('click', function(){
      var bgId = "." + $(this).attr('custom-id');
      $(bgId).css('opacity', '1');
    });
  },
  initCheckboxes(){
    if($(".form-section__form").length){
      var waitForForm = setInterval(function(){
        if($(".hs-form-checkbox-display")){
          setTimeout(function(){
            $(".hs-form-checkbox-display").append('<div class="checkbox-element"></div>');
            clearInterval(waitForForm);
          },1000);
        }
      },100)
    }
  },
  initFooter(){
    var customLink = $('footer li a[href*="#"]:first-child');
    // $(customLink).eq(0).parent().addClass('spacing');
    $(customLink).on('click', function(e){
      const elm = e.currentTarget;
      const skip = $(elm.hash).length && elm.hash == '#getInTouch';
      if (!skip) {
        e.stopPropagation();
        e.preventDefault();
      }
    });
  },
  initHeader(){
    $(window).scroll(function() {
     if($(window).scrollTop() > 40) $(".header__content").addClass("header__content--scroll");
     else $(".header__content").removeClass("header__content--scroll");
    });
  },
  initHeadroom() {
    let theHeader = document.querySelector('.js-header');
    let headroom = new Headroom(theHeader, { offset: 100, tolearnce: 5 });
    headroom.init();
  },
  triggerModulePopup(event) {
    event.preventDefault();
    const { currentTarget } = event;

    $('.js-dashboard figcaption').removeClass('is-active');

    $(currentTarget).find('figcaption').addClass('is-active')
  },
  openShareWindow(event) {
    let link = $(event.currentTarget).attr('href');
    if (link.substr(0, 6) != 'mailto') {
      event.preventDefault();
      window.open(link, '', 'width=600, height=400');
    }
  },
  showMobileNav(event) {
    event.preventDefault();
    this.$mobileNav.toggleClass('is-active');
    this.$hamburger.toggleClass('is-active');
    if (this.$mobileNav.hasClass('is-active')) {
      this.$hamburger.attr('aria-pressed', 'true');
    } else {
      this.$hamburger.removeAttr('aria-pressed');
    }
  },

  goToGetStartedForm(event) {
    const elm = event.currentTarget;
    const fallback = $(elm.hash).length && elm.hash == '#getInTouch' ? false : `${window.location.protocol}//${window.location.host}/${elm.hash}`;
    this.scrollToSection(event, fallback);
  },
  scrollToSection(event, fallback) {
    const elm = event.currentTarget;
    const section = typeof elm.hash !== undefined && elm.hash != '#' ? elm.hash : null;
    event.preventDefault();

    if (section && !fallback) {
      $('html, body').animate({
        scrollTop: $(section).offset().top
      }, 1000);
    }

    if (fallback) location.href = fallback

    this.$mobileNav.removeClass('is-active');
    this.$hamburger.removeClass('is-active');
    this.$hamburger.removeAttr('aria-pressed');
  },
  initAjax() {
    const { ajaxLoadMore } = this;
    const blog = {
      listSel: '.js-posts',
      selector: '.js-loadmore'
    }

    $(blog.selector).on("click", (e) => { ajaxLoadMore(e, blog) });
  },
  ajaxLoadMore (e, obj) {
    const { ajaxUrl } = LOCALS
    const wrapper_classes = $(e.currentTarget).data('itemclasses');
    const offset = $(e.currentTarget).data('offset');
    const author = $(e.currentTarget).data('author');
    const category = $(e.currentTarget).data('category');
    const type = $(e.currentTarget).data('type');
    let { selector, listSel } = obj
    let data = { offset, type, wrapper_classes }

    data.action = $(e.currentTarget).data('action') ? $(e.currentTarget).data('action') : 'loadmore_posts_featured'

    e.preventDefault()

    e.stopPropagation()

    if (author) {
      data.author = author
    }

    if (category) {
      data.category = category
    }
    
    data.curr_page =  $(listSel).attr('data-paged') ? $(listSel).attr('data-paged') : 1;
    data.curr_page++;
    $(listSel).attr('data-paged', data.curr_page);

    jQuery.ajax({
      url: ajaxUrl,
      type: 'POST',
      data,
      beforeSend:function(xhr){
        $('#ajax-loading').show();
        $('html, body').animate({
          scrollTop: $('#ajax-loading').offset().top-$('.js-header').innerHeight()
        }, 1000);
        $(selector).hide();
      },
      success: function(data){

        $('#ajax-loading').hide();

        const $data = $(data);
        const max = $($data[0]).data('max');
        const curr_page = $(listSel).attr('data-paged');

        if ($data.length && !$data.hasClass('no-results') ) {
          $(listSel).append($data);

          if($data.length < offset || max == curr_page) {
            $(selector).hide();
          } else {
            $(selector).show();
          }
        } else {
          $(selector).hide();
        }
      }
    });

    return false;
  },
  resetDashBoardState(event) {
    const { target } = event;

    $('.js-dashboard figcaption').length &&
    ! $(target).closest('.js-dashboard').length &&
    $('.js-dashboard figcaption').removeClass('is-active');

  },
  onFormSubmit(event, id) {
    if (id == 2) this.sendFormSubmitEventGA('Get Started Form', 'FormConfirmation', 'Submit')
  },
  sendFormSubmitEventGA(eventCategory = '', eventAction = '', eventLabel = '', eventValue = '', fieldsObject = {}) {},
  checkIE11() {
    const uaString = navigator.userAgent;
    const match = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(uaString);
    if (match && match[2] == 11) this.$html.addClass('ie11');
    if($(".ie11").length){
      setTimeout(function(){
        $(".ie11 .home .hero__content h1").fadeIn(1300);
        $(".ie11 .hero__content h1").fadeIn(1300);
      }, 300);
      if($('.js-quotes').length){
        $('.js-quotes').slick({
          autoplay: true,
          autoplaySpeed: 2500,
          infinite: true,
          speed: 400,
          fade: true,
          cssEase: 'linear',
          initialSlide: 1,
          arrows: false,
          buttons: false,
          dots: true
        });
      }
      $(".open-card").on('click', function(){
        $(this).siblings(".jobs__details").find(".jobs__info").slideToggle();
        $(this).find(".open").toggleClass("visible");
        $(this).find(".hide").toggleClass("visible");
        $(this).parent().find(".jobs__cta").toggleClass("visible");
      });
      if($(".form-section__form").length){
        var waitForForm = setInterval(function(){
          if($(".hs-form-checkbox-display")){
            setTimeout(function(){
              $(".hs-form-checkbox-display").append('<div class="checkbox-element"></div>');
              clearInterval(waitForForm);
            },1000);
          }
        },1200)
      }
    }
  }
};

Mangrove.init();
