import $ from 'jquery'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import TweenMax from "gsap/all";
import sal from 'sal.js';

function triggerHeadlineFadeIn(){
    var homepage = $('body.home');
    if(homepage){
        sal({
            threshold: 0.1,
        });
    }
}

function triggerSideImageAnimations() {
    var images = $('.building-block--animated-home .title-images-cta__image');
    var cta = $('.building-block--animated-home .button-group');

    gsap.utils.toArray(images).forEach((elem, i) => {
        gsap.set(elem, { x: '0', opacity: 1 })
        var anim = function (position) {
            gsap.from(elem, {
                x: position,
                opacity: 0,
                ease: "power2.inOut",
                duration: 1.7,
                scrollTrigger: {
                    trigger: elem,
                    start: "100px 100%",
                }
            });
        }
        if (i % 2 === 0) anim('-80%')
        else anim('80%')
    });

    gsap.fromTo(
        cta,
        { opacity: 0, y: '40%' },
        {
            y: 0,
            opacity: 1,
            ease: "power2.inOut",
            duration: 1.5,
            scrollTrigger: {
                trigger: cta,
                start: "top 100%",
            }
        }
    );
}

function triggerFadeUpAnimations() {
    var boxes = gsap.utils.toArray('.building-block--animated-home .js-cta-card');
    var arrLength = boxes.length;
    boxes.forEach((element, index) => {
        var newIndex = arrLength - index;
        gsap.fromTo(
            element,
            { opacity: 0, y: '20%' },
            {
                opacity: 1,
                y: '0px',
                duration: 1,
                ease: 'power1.out',
                onStart: function () {
                    setTimeout(() => {
                        $(element).find('h2').css('opacity', '1');
                        $(element).find('.cards-cta__cards__text').css('opacity', '1');
                    }, 200);
                },
                scrollTrigger: {
                    trigger: element,
                    start: `-40% ${newIndex * 130}px`, // start position based on index
                    end: `-40% ${newIndex * 130 + 50}px`, // end position based on index
                    // toggleActions: 'play none none reverse',
                },
            }
        );
    });
}

// Key features SVG animations
var timeline1 = gsap.timeline({paused:true});
var timeline2 = gsap.timeline({paused:true});
var timeline3 = gsap.timeline({paused:true});
var timeline4 = gsap.timeline({paused:true});

function drawBottomSVGLines(){
    // left line
    timeline1.to("#line1-1", { opacity: 1, duration: 0.1 }, 0)
    timeline2.to("#line2-1", { opacity: 1, duration: 0.1 }, 0)
    timeline3.to("#line3-1", { opacity: 1, duration: 0.1 }, 0)

    var lines1 = gsap.utils.toArray('.svgLine1');
    lines1.forEach((element) => {
        var l = element.getTotalLength();
        gsap.set(element, {
            strokeDasharray: l
        });
        timeline1.fromTo(element, { strokeDashoffset: l }, { strokeDashoffset: 0, duration: .2 })
    });

    // middle line
    var lines2 = gsap.utils.toArray('.svgLine2');
    lines2.forEach((element) => {
        var l = element.getTotalLength();
        gsap.set(element, {
            strokeDasharray: l
        });
        timeline2.fromTo(element, { strokeDashoffset: l }, { strokeDashoffset: 0, duration: .7 })
    });

    // right line
    var lines3 = gsap.utils.toArray('.svgLine3');
    lines3.forEach((element) => {
        var l = element.getTotalLength();
        gsap.set(element, {
            strokeDasharray: l
        });
        timeline3.fromTo(element, { strokeDashoffset: l }, { strokeDashoffset: 0, duration: .2 })
    });

    timeline1.to("#path1-cap", { opacity: 1, duration: 0.3, delay: .2 })
    timeline2.to("#path2-cap", { opacity: 1, duration: 0.3, delay: .1 })
    timeline3.to("#path3-cap", { opacity: 1, duration: 0.3, delay: .2 })
}

function drawTopSVGLine(){
    // top line
    timeline4.to("#line4-1", { opacity: 1, duration: 0.1 }, 0)
    var lines4 = gsap.utils.toArray('.svgLine4');
    lines4.forEach((element) => {
        var l = element.getTotalLength();
        gsap.set(element, {
            strokeDasharray: l
        });
        timeline4.fromTo(element, { strokeDashoffset: l }, { strokeDashoffset: 0, duration: .5 })
    });
    timeline4.to("#path4-cap", { opacity: 1, duration: 0.3, delay: .1 })
}

function triggerGrowthAnimations() {
    var timeline = gsap.timeline();
    gsap.set('.growth__img', {
        x: 80,
        opacity: 0,
        ease: 'power3.out',
    });

    gsap.set('.js-animate-tab-link', {
        opacity: 0,
        ease: 'none',
    });

    timeline.to("#gsapAnim1", { x: 0, duration: .5, opacity: 1, }).to("#gsap-anim1", {duration: .2, opacity: 1, }, "-=.5");
    timeline.add(timeline1.play(), .5)
    timeline.add(timeline2.play(), .5)
    timeline.add(timeline3.play(), .5)
    timeline.to("#gsapAnim2", { x: 0, duration: .5, opacity: 1, }).to("#gsap-anim2", {duration: .2, opacity: 1, }, "-=.5");
    timeline.add(timeline4.play(), 2)
    timeline.to("#gsapAnim3", { x: 0, duration: .5, opacity: 1, }).to("#gsap-anim3", { duration: .2, opacity: 1, }, "-=.5");
    timeline.to("#gsap-anim4", { duration: .5, opacity: 1, delay: .3 });

    ScrollTrigger.create({
        trigger: '#gsapAnim1',
        start: '20% bottom',
        end: 'bottom bottom',
        animation: timeline,
        // toggleActions: "restart none none reverse" 
    });
}

function init() {
    gsap.registerPlugin(ScrollTrigger)
    triggerHeadlineFadeIn()
    triggerSideImageAnimations()
    triggerFadeUpAnimations()
    drawBottomSVGLines()
    drawTopSVGLine()
    triggerGrowthAnimations()
}

$(document).ready(init)
