const $doc = $(document);
const $win = $(window);
const $html = $('html');
const $body = $('body');
const $mainWrap = $('main');
const $header = $('.js-header');
const $mobileNav = $('.js-mobile-nav');
const $hamburger = $('.js-hamburger');

function resetNav(event) {
  const { target, type } = event;
  const $extendedNav = $('.sub-menu--extended');
  const $subMenus = $('.sub-menu');
  const $navItems = $('.nav--main a');
  const $extendedNavInner = $('.sub-menu--extended-inner');

  const $header = $('.header');
  const isNotNav = !$(target).closest('.is-active').length &&
                !$(target).hasClass('sub-menu--extended') &&
                !$(target).hasClass('sub-menu--extended-inner') &&
                !$(target).hasClass('sub-menu') &&
                !$(target).hasClass('sub-menu__header')&&
                !$(target).hasClass('header-curtain') &&
                !$(target).hasClass('header') &&
                !$(target).closest('.menu-item').length &&
                !$(target).is('li') &&
                !$(target).is('ul');

  if ( type == 'click'  && isNotNav || type == 'scroll' ) {
    $navItems.removeClass('is-active');
    $extendedNav.removeClass('is-active');
    $extendedNavInner.removeClass('is-active');
    $subMenus.removeClass('is-active');
    $header.removeClass('has-open-menu');
    closeSubMenu()
  }
  if (type == 'scroll') {
    $mobileNav.removeClass('is-active');
    $hamburger.removeClass('is-active');
  }
}

function removeSubmenus() {
  const $navWithChildren = $('.menu-item-has-children');

  $navWithChildren.each(function() {
    const $navItem = $(this);

    if ( $navItem.find('.sub-menu').length && $navItem.find('.sub-menu--extended').length ) {
      $navItem.find('.sub-menu').remove()
   }

   if ( $navItem.find('.has-promo').length && !$navItem.find('.sub-menu--extended').length ) {
    $navItem.find('.sub-menu:not(.has-promo)').remove()
   }


  })
}

function openSubMenu(e) {
  const $navItem = $(e.target).is('a') ? $(e.target).closest('.menu-item-has-children') : $(e.target);
  const $header = $('.header');

  if ( !$(e.target).is('li') && !$(e.target).is('a') ) return false

  $('.menu-item-has-children').removeClass('is-active')

  if ( $navItem.hasClass('menu-item-has-children') ) e.preventDefault();

  $navItem.addClass('is-active');
  $header.addClass('has-open-menu');

}

function closeSubMenu(e) {
  $('.menu-item-has-children').removeClass('is-active')
}

$doc.on(
  'mouseenter',
  '.nav--main > ul > li',
  event => openSubMenu(event)
);

$doc.on(
  'mouseover',
  'main, .nav--main .menu-item:not(.menu-item-has-children)',
  event => closeSubMenu(event)
);

$win.on(
  'scroll',
  event => {
    resetNav(event)
  }
);