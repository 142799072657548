import $ from 'jquery'

const $doc = $(document)

function toggleAccordionItem(e) {
  e.preventDefault()
  
  let el = $(this);
  if ( el.parent().hasClass('active') ) {
    el.attr('aria-expanded', 'false');
  } else {
    el.attr('aria-expanded', 'true');
  }

  $('.js-mobile-nav .menu-item-has-children').not(el.parent()).find('.sub-menu').slideUp('fast');
  $('.js-mobile-nav .menu-item-has-children').not(el.parent()).removeClass('active');
  el.parent().find('.sub-menu').slideToggle('fast');
  el.parent().toggleClass('active');
}

export default function initNavAccordion(){
  $doc.on('click', '.js-mobile-nav .menu-item-has-children > a', toggleAccordionItem )
}
